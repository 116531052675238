<template>
    <div class="menu" vi-if="chef">
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-container>
          <v-row align-content="center">
            <v-col class="col-12 col-md-3" @click="$router.push('/book');"><v-btn icon rounded href="/book" ><v-icon size="36px">mdi-chevron-left</v-icon></v-btn>Edit Booking</v-col>
            <v-col class="col-12 col-md-6" align-self="center" align="center"><h2>Please confirm the booking details</h2></v-col>
            <v-col class="col-12 col-md-3"></v-col>
          </v-row>
            <v-row>
                <div class="col-xs-12 col-md-7">
                  <v-card >
                    <div class="">
                      <div style="text-align: center;font-size: medium;font-weight: 700;padding: 12px;border-radius: 4px;background-color: #f7f3f3;">
                        Your Booking with {{this.$root.chefSelected.profile.profileName}}</div>
                      <v-col>
                        <v-text-field
                            hide-details
                            dense
                            :value="$store.state.user.userDetails.firstname + ' ' + $store.state.user.userDetails.lastname"
                            label="Name"
                            prepend-icon="mdi-account"
                            readonly
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                            readonly
                            dense
                            :value="$store.state.user.userDetails.phone"
                            label="Phone"
                            prepend-icon="mdi-phone"
                            :rules="[v => !!v || 'Phone is required']"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-autocomplete
                            :no-filter="true"
                            hide-details
                            readonly
                            v-model="$root.formData.location"
                            :items="$root.formData.locationItems"
                            :loading="isLoading"
                            :search-input.sync="lookupAddress"
                            item-text="place_name"
                            label="Location"
                            placeholder="Start typing your address"
                            prepend-icon="mdi-map-marker"
                            append-icon=""
                            return-object
                            @change="getLocation"
                            auto-select-first
                            hide-no-data
                            dense
                            :rules="[v => !!v || 'Location is required', v => this.checkRange()[0] || 'Chef out of range by '+this.checkRange()[1]+' Km']"
                        ></v-autocomplete>
                      </v-col>
                      <v-col>
                            <v-text-field
                                hide-details
                                dense
                                v-model="$root.formData.date"
                                label="Date"
                                prepend-icon="mdi-calendar-range"
                                readonly
                                :rules="[v => !!v || 'Date is required']"
                            ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                            readonly
                            hide-details
                            dense
                            prepend-icon="mdi-clock"
                            :items="availableTimes"
                            label="Time"
                            v-model="$root.formData.time"
                            :rules="[v => !!v || 'Time is required']"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                            hide-details
                            readonly
                            dense
                            prepend-icon="mdi-account-multiple"
                            label="Guests"
                            type="number"
                            :min="serviceSelectedCd.minGuests!==undefined?serviceSelectedCd.minGuests:2"
                            :max="serviceSelectedCd.maxGuests!==undefined?serviceSelectedCd.maxGuests:20"
                            v-model.number="$root.nbGuests"
                            :rules="[v => !!v || 'Number of guests is required', v => (serviceSelectedCd.minGuests===undefined || v>=serviceSelectedCd.minGuests) || 'Minimum required is '+serviceSelectedCd.minGuests]"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                            hide-details
                            readonly
                            dense
                            prepend-icon="mdi-account-multiple"
                            label="Kids"
                            type="number"
                            v-model.number="$root.nbKids"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-textarea
                            hide-details
                            readonly
                            prepend-icon="mdi-comment"
                            dense
                            v-model="$root.comment"
                            label="Comment"
                            hint="Tell the chef any allergy requirement or any additional information"
                            placeholder="Tell the chef any allergy requirement or any additional information"
                        >
                        </v-textarea>
                      </v-col>


                    </div>
                  </v-card>

                  <v-divider style="margin-top: 12px;margin-bottom: 12px;"></v-divider>

                  <v-card > <!-- Location Details-->
                    <div style="text-align: center;font-size: medium;font-weight: 700;padding: 12px;border-radius: 4px;background-color: #f7f3f3;">
                      Location Details</div>
                      <v-col >
                        <div style="font-size: 12px" class="primary--text">What Equipments do you have in your kitchen? (Please select)</div>
<!--                        <v-input v-model="equipmentsSelected" :rules="[v => v.length>0 || 'This field is required!' ,this.validateEquipmentsKitchen()===undefined || this.validateEquipmentsKitchen()]">-->
                        <v-item-group multiple v-model="equipmentsSelected">
                          <v-item
                              v-for="tag in equipmentsKitchen"
                              :key="tag.displayName"
                              v-slot:default="{ active, toggle }"
                              :value="tag"
                              color="primary"
                          >
                            <v-chip
                                active-class="primary--text"
                                :input-value="checkChip(equipmentsSelected,tag)"
                                filter
                                @click="toggle"
                            >
                              <v-icon v-if="!checkChip(equipmentsSelected,tag)" left>mdi-checkbox-blank-outline</v-icon> {{ tag.displayName }}
                            </v-chip>
                          </v-item>
                        </v-item-group>
<!--                        </v-input>-->
                      </v-col>
                    <v-row class="container">
                      <v-col cols="auto" style="align-self: center;padding: 0px 12px 0px 12px;" >
                        <div style="font-size: 12px" class="primary--text">What Cooktop Type do you have? (Please select)</div>
                        <v-input v-model="cooktopType" :rules="[v => !!v || 'This field is required!']" >
                        <v-item-group v-model="cooktopType" >
                          <v-item
                              v-for="tag in hobType"
                              :key="tag.name"
                              v-slot:default="{ active, toggle }"
                              :value="tag"
                              color="primary"
                          >
                            <v-chip
                                active-class="primary--text"
                                :input-value="cooktopType !==undefined && tag.name ===cooktopType.name"
                                filter
                                @click="toggle"
                            >
                              <v-icon v-if="cooktopType ===undefined || tag.name !==cooktopType.name" left>mdi-checkbox-blank-circle-outline</v-icon> {{ tag.displayName }}
                            </v-chip>
                          </v-item>
                        </v-item-group>
                        </v-input>
                      </v-col>
                    </v-row>
                    <v-col cols="auto" style="align-self: center;padding: 0px 12px 0px 12px;" >
                      <v-radio-group
                          id="location"
                          color="primary--text"
                          v-model="locationHome"
                          row
                          label="Is the event at your home ?"
                          :rules="[v => v!==undefined  || 'This field is required!']"
                      >
                        <v-radio
                            id="location_yes"
                            label="Yes"
                            :value="true"
                        ></v-radio>
                        <v-radio
                            id="location_no"
                            label="No"
                            :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col cols="auto" style="align-self: center;padding: 0px 12px 0px 12px;" v-if="locationHome !==undefined && !locationHome">
                      <v-select
                          v-model="locationType"
                          :items="$store.state.locationType"
                          item-text="displayName"
                          item-value="name"
                          attach
                          chips
                          label="Location type"
                          color="#00695C"
                          :rules="[v => !!v && v.length !== 0 || 'You must select one location type!']"
                          required
                          return-object
                      ></v-select>
                    </v-col>
                    <v-row class="container" v-if="Object.keys(getServiceCutlery.equipmentsService).length !== 0">
                      <v-col cols="auto" style="align-self: center;padding: 0px 12px 0px 12px;" >
                        <v-radio-group
                            id="equipment"
                            color="primary--text"
                            v-model="equipmentRequired"
                            row
                            label="Do you have the following equipments ?"
                            :rules="[v => v!==undefined  || 'This field is required!']"
                        >
                          <v-radio
                              id="equipment_yes"
                              label="Yes"
                              :value="true"
                          ></v-radio>
                          <v-radio
                              id="equipment_no"
                              label="No"
                              :value="false"
                          ></v-radio>
                        </v-radio-group>
                        <ul class="margin-left-default" v-for="(value,keyService) in getServiceCutlery.equipmentsService" :key="keyService">
                          <li v-if="equipmentsService.find(e => e.name===keyService)!==undefined">
                              {{value}} {{equipmentsService.find(e => e.name===keyService).displayName}}
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-divider style="margin-top: 12px;margin-bottom: 12px;"></v-divider>

                  <v-card > <!-- Service Description -->
                    <div style="text-align: center;font-size: medium;font-weight: 700;padding: 12px;border-radius: 4px;background-color: #f7f3f3;">
                      Service Description </div>
                    <v-col>
                      <p>The chef brings all ingredients, prepares the meal in your kitchen, serves each dish, and does the washing up before leaving</p>
                      <p v-if="getServiceCutlery.description!==undefined">{{getServiceCutlery.description}}</p>
                      <p class="warning--text" v-if="getServiceCutlery.warning!==undefined">{{getServiceCutlery.warning}}</p>
                    </v-col>
                  </v-card>
                </div>

                <!-- Booking-->
                <div class="col-xs-12 col-md-5">
                    <!-- Cart -->
                    <v-card dark>
                        <v-container>
                            <h2>Price Details</h2>
                        </v-container>
                        <div class="background-black-light">
                          <!--                          PACKAGE ------------------------------>
                          <div v-if="packageSelectedCd" >
                            <div class="row" style="width:100%;margin: 0px">
                              <v-col>{{packageSelectedCd.name}} x {{$root.nbGuests}}</v-col>
                              <v-col class="col-auto" align-self="end" v-if="packageSelectedCd.price!==undefined && $root.nbGuests!==undefined">
                                {{roundToXDigits((packageSelectedCd.price) * $root.nbGuests,2)}}
                              </v-col>
                            </div>
                            <div v-for="(packageCategory) in packageCategoriesSorted(packageSelectedCd)" :key="packageCategory.name">
                              <div class="row" style="margin: 0px" >
                                <v-col>{{packageCategory.itemCategory.name}}</v-col>
                                <v-col class="col-auto" align-self="end">
                                </v-col>
                              </div>
                              <div class="row" style="font-size: 14px;width:100%;margin: 0px" v-for="(dish,index) in $root.dishesSelected[packageCategory.itemCategory.name]" :key="index">
                                <v-col style="text-indent: 10px;">{{dish.description}} </v-col>
                                <v-col class="col-auto" align-self="end">
                                </v-col>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <!--                            A LA CARTE ---------------------------->

                            <!--                                <div v-for="(dishType,indexDishType) in $store.state.dishType" :key="indexDishType">-->
                                  <div v-for="(category) in serviceSelectedCd.itemCategories" :key="category.name" class="margin-top-default">
                                  <div v-if="category.multiSelect===true && $root.dishesSelected[category.name]!==undefined && $root.dishesSelected[category.name].length!==undefined ">
                                    <div class="row" style="width:100%;margin: 0px" v-if="category.categoryType.name!=='KID' ">
                                      <v-col>{{category.name}} x {{$root.nbGuests}}</v-col>
                                      <v-col class="col-auto" align-self="end">
                                        {{roundToXDigits(($root.dishesSelected[category.name+"price"]) * $root.nbGuests)}}
                                      </v-col>
                                      <div class="row" style="font-size: 14px;width:100%;margin: 0px" v-for="(dish,index) in $root.dishesSelected[category.name]" :key="index">
                                        <v-col style="text-indent: 10px;">{{dish.description}} </v-col>
                                        <v-col class="col-auto" align-self="end">
                                          {{roundToXDigits(getPriceWithCommission((dish.price) * $root.nbGuests))}}
                                        </v-col>
                                      </div>
                                    </div>
                                    <div  v-else>
                                      <div class="row" style="margin: 0px" v-if="$root.nbKids>0">
                                        <v-col>{{dishType.displayName}} x {{$root.nbKids}}</v-col>
                                        <v-col class="col-auto" align-self="end">
                                          {{roundToXDigits(($root.dishesSelected[category.name+"price"]) * $root.nbKids)}}
                                        </v-col>
                                        <div class="row" style="font-size: 14px;width:100%;margin: 0px" v-for="(dish,index) in $root.dishesSelected[category.name]" :key="index">
                                          <v-col style="text-indent: 10px;">{{dish.description}} </v-col>
                                          <v-col class="col-auto" align-self="end">
                                            {{roundToXDigits(getPriceWithCommission((dish.price) * $root.nbKids))}}
                                          </v-col>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div v-if="category.multiSelect!==true && $root.dishesSelected[category.name]!==undefined ">
                                        <div class="row" style="margin: 0px" v-if="category.categoryType.name!=='KID' ">
                                            <v-col>{{category.name}} x {{$root.nbGuests}}</v-col>
                                            <v-col class="col-auto" align-self="end">
                                                {{roundToXDigits(getPriceWithCommission($root.dishesSelected[category.name].price) * $root.nbGuests)}}
                                            </v-col>
                                        </div>
                                        <div v-else>
                                          <div class="row" style="margin: 0px" v-if="$root.nbKids>0">
                                            <v-col>{{category.name}} x {{$root.nbKids}}</v-col>
                                            <v-col class="col-auto" align-self="end">
                                                {{roundToXDigits(getPriceWithCommission($root.dishesSelected[category.name].price) * $root.nbKids)}}
                                            </v-col>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                          </div>
<!--                            <v-divider></v-divider>-->
<!--                            <div  class="row" style="margin: 0px">-->
<!--                                <v-col>Service Fee</v-col>-->
<!--                                <v-col class="col-auto" align-self="end">-->
<!--                                    {{serviceFee()}}-->
<!--                                </v-col>-->
<!--                            </div>-->
                            <v-divider  v-if="minChef"></v-divider>
                            <div  class="row" style="margin: 0px" v-if="minChef">
                                <v-col>Minimum order remaining</v-col>
                                <v-col class="col-auto" align-self="end">
                                    {{minChef}}
                                </v-col>
                            </div>
                            <div v-if="minChef" class="row" style="margin: 0px" >
                                <v-col align="center" class="caption" >
                                    <div>The chef requires a minimum order amount of {{roundToXDigits(getPriceWithCommission(serviceSelectedCd.minPrice))}}</div>
                                </v-col>
                            </div>
                            <v-divider  v-if="waiterPrice"></v-divider>
                            <div  class="row" style="margin: 0px" v-if="waiterPrice">
                                <v-col>Waiter</v-col>
                                <v-col class="col-auto" align-self="end">
                                    {{waiterPrice}}
                                </v-col>
                            </div>
                            <div v-if="waiterPrice" class="row" style="margin: 0px" >
                                <v-col align="center" class="caption" >
                                    <div>The chef requires a wait person for this order</div>
                                </v-col>
                            </div>
                            <v-divider v-if="errorDishes"></v-divider>
                            <div v-if="errorDishes" class="row" style="margin: 0px" >
                                <v-col align="center" class="error--text" >
                                    <div>Select a Starter or a Desert</div>
                                </v-col>
                            </div>
                        </div>
                        <v-divider></v-divider>
                        <div  class="row" style="margin: 0px;font-size: 18px">
                            <v-col>Total</v-col>
                            <v-col class="col-auto" align-self="end" v-if="!Number.isNaN(total())">
                                {{total()}}
                            </v-col>
                        </div>
                      <v-divider></v-divider>
                      <div  class="row" style="margin: 0px">
                        <v-col align="center">
                          <div class="caption">
                            By clicking “Confirm and Pay” below,
                            you agree to our <a @click.stop href="/terms" target="_blank">Terms and Conditions</a> and our <a @click.stop href="/privacy" target="_blank">Privacy Policy</a>.
                          </div>
<!--                            <v-stripe-card-->
<!--                                dark-->
<!--                                clearable-->
<!--                                ref="elementRef1"-->
<!--                                v-model="source"-->
<!--                                create="token"-->
<!--                                api-key="pk_test_51J8OdNAGb7MTLpmkowUVyVsfWEUSx6R98IGrcoqG6QnKOuPGBozH0RECZGhDess3WLvOIkJrD6NaIrzpDNEeOf0Q00vuPGeSiG"-->
<!--                            ></v-stripe-card>-->
                          <stripe-element-card :pk="stripeKey"
                                               ref="elementRef"
                                               @token="save"
                                                class="margin-default">
                          </stripe-element-card>
                          <div v-if="env!=='prod'">
                            4242 4242 4242 4242 08/24 123 94107 //success
                            4000 0025 0000 3155 08/24 123 94107 //authorisationrequired
                            4000 0000 0000 9995 08/24 123 94107 //fail
                          </div>
                          <v-btn id="btn_pay" outlined color="primary" class="margin-default" :disabled="!valid"  @click="startPaymentProcess()" :loading="isLoadingPayment">Confirm And Pay</v-btn>
                          <div class="error--text " v-if="!valid">some errors are present</div>
                          <div class="error--text " v-if="error">{{ error }}</div>
                          <v-btn outlined color="primary" class="margin-default" v-if="error" @click="$router.push('/book');" >Edit</v-btn>
                        </v-col>
                      </div>
                    </v-card>
                </div>
            </v-row>
        </v-container>
      </v-form>

    </div>
</template>

<script>

  import Vue from "vue";
    // import {mapState} from "vuex";
    import {mapGetters} from 'vuex';
    import Constants from "@/constants";
    import { StripeElementCard } from '@vue-stripe/vue-stripe';
    // import { VStripeCard } from 'v-stripe-elements'
    // import chefs from "../chefs" //contain the fake db of chefs

    function initData(){
      let tomorrowDate=new Date();
      tomorrowDate.setDate(tomorrowDate.getDate()+2);
        return {
          //checkout
          source:null,
          isLoadingPayment:false,
          equipmentsSelected: [],
          cooktopType: undefined,
          locationHome:undefined,
          locationType:undefined,
          equipmentRequired:undefined,
          address:undefined,
          error:undefined,
          stripeKey:process.env.VUE_APP_STRIPE_API_KEY,
          env: process.env.VUE_APP_ENV,
          //

            valid:true,
            tomorrow:tomorrowDate.toISOString() ,
            isLoading:false,
            resultLocations:undefined,
            lookupAddress:undefined,
            outOfRange:false,
            // total:0,
            waiterPrice:undefined,
            minChef:undefined,
            data: {
                comment:undefined,
                appetizer: null,
                starter: null,
                main: null,
                desert: null,
                kid: null,
                canapes: null,
                grazing: null,
                loaded: false,
                aboutChef: false,
                guests:undefined,
                gallery: false,
                photoDetails: false,
                photoIndex: 0,
                datepicker:false
            }
        };
    }

    import LoadScript from 'vue-plugin-load-script'
    Vue.use(LoadScript)
    import { StripePlugin } from '@vue-stripe/vue-stripe';
    console.log(window.location.origin);

    let pk=process.env.VUE_APP_STRIPE_API_KEY;
    const options = {
      //pk: "pk_test_51J8OdNAGb7MTLpmkowUVyVsfWEUSx6R98IGrcoqG6QnKOuPGBozH0RECZGhDess3WLvOIkJrD6NaIrzpDNEeOf0Q00vuPGeSiG",
      pk: pk,
      // stripeAccount: process.env.STRIPE_ACCOUNT,
      // apiVersion: process.env.API_VERSION,
      // locale: process.env.LOCALE,
    };

    new Promise((resolve) => {
      let script = document.createElement('script')
      script.onload = () => {
        resolve();
      }
      // script.async = true;
      script.defer = true;
      script.src = 'https://js.stripe.com/v3';
      document.head.appendChild(script);
    }).then(()=>{
      Vue.use(StripePlugin, options);
    })

    export default {
      components: {
        StripeElementCard,
      },
      name: "Menu",
      data() {
            return initData();
        },
      created(){
        if (this.$root.formData.location!==undefined){
          let payload={address:this.$root.formData.location.place_name};
          let that=this;
          this.$store.dispatch('searchAddress', payload)
              .then((response) => {
                if (response !== undefined && response.statusCode === 200 && response.result !== undefined) {
                  console.log(response);
                  that.address=response.result;
                  if (response.result.addressDetails!=undefined){
                    if (response.result.addressDetails.kitchenEquipments!==undefined){
                      response.result.addressDetails.kitchenEquipments.forEach(ke => {
                        this.equipmentsKitchen.forEach(ek => {
                          if (ek.name===ke.name){
                            that.equipmentsSelected.push(ek);
                          }
                        })
                      })
                    }
                    // that.equipmentsSelected=response.result.addressDetails.kitchenEquipments;
                    that.locationType=response.result.addressDetails.locationType;
                    that.cooktopType=response.result.addressDetails.cooktopType;
                  }
                }
              });
        }else{
          this.$router.push({path: `/`})
        }
      },
      computed: {
        // checkout
        equipmentsService() {
          return this.$store.state.equipmentService;
        },
        equipmentsKitchen() {
          return this.$store.state.equipmentKitchen;
        },
        hobType() {
          return this.$store.state.hobType;
        },
        getServiceCutlery(){
          let equipments={equipmentsService:{},equipmentsKitchen:[],description:""};
          for (const dishType in this.$root.dishesSelected){
            // Function Cocktail
            if (this.$root.dishesSelected[dishType]!==undefined && this.$root.dishesSelected[dishType].length!==undefined){
              for (const dishIndex in this.$root.dishesSelected[dishType]){
                const dish=this.$root.dishesSelected[dishType][dishIndex];
                for (const equipmentIndex in this.$store.state.equipmentService) {
                  let equipment=this.$store.state.equipmentService[equipmentIndex];
                  if (dish.equipments!==undefined && dish.equipments.filter(e => e!==undefined && e.name === equipment.name).length>0) {
                    if (equipments.equipmentsService[equipment.name]===undefined){
                      equipments.equipmentsService[equipment.name]=0;
                    }
                    if (dishType=='KID' && this.$root.nbKids!=null){
                      equipments.equipmentsService[equipment.name]+=this.$root.nbKids;
                    }else if (dishType!=='KID' && this.$root.nbGuests!=null){
                      equipments.equipmentsService[equipment.name]+=this.$root.nbGuests;
                    }
                  }
                }
                // Kitchen Equipment
                for (const equipmentIndex in this.$store.state.equipmentKitchen) {
                  let equipment = this.$store.state.equipmentKitchen[equipmentIndex];
                  if (dish.equipments!==undefined && dish.equipments.filter(e => e!==undefined && e.name === equipment.name).length>0) {
                    if (equipments.equipmentsKitchen[equipment.name]===undefined){
                      equipments.equipmentsKitchen[equipment.name]=equipment;
                    }
                  }
                }
              }
            }else if (this.$root.dishesSelected[dishType]!==undefined){
              for (const equipmentIndex in this.$store.state.equipmentService) {
                let equipment=this.$store.state.equipmentService[equipmentIndex];
                if (this.$root.dishesSelected[dishType].equipments!==undefined && this.$root.dishesSelected[dishType].equipments.filter(e => e!==undefined && e.name === equipment.name).length>0) {
                  if (equipments.equipmentsService[equipment.name]===undefined){
                    equipments.equipmentsService[equipment.name]=0;
                  }
                  if (dishType=='KID' && this.$root.nbKids!=null){
                    equipments.equipmentsService[equipment.name]+=this.$root.nbKids;
                  }else if (dishType!=='KID' && this.$root.nbGuests!=null){
                    equipments.equipmentsService[equipment.name]+=this.$root.nbGuests;
                  }
                }
               }
              // Kitchen Equipment
              for (const equipmentIndex in this.$store.state.equipmentKitchen) {
                let equipment = this.$store.state.equipmentKitchen[equipmentIndex];
                if (this.$root.dishesSelected[dishType].equipments!==undefined && this.$root.dishesSelected[dishType].equipments.filter(e => e!==undefined && e.name === equipment.name).length>0) {
                  if (equipments.equipmentsKitchen[equipment.name]===undefined){
                    equipments.equipmentsKitchen[equipment.name]=equipment;
                  }
                }
              }
            }
          }
          let fridge=equipments.equipmentsKitchen["FRIDGE"]!==undefined;
          let freezer=equipments.equipmentsKitchen["FREEZER"]!==undefined;
          let gaz=(this.cooktopType!==undefined && this.cooktopType.name==="GAZ");
          let induction=(this.cooktopType!==undefined && this.cooktopType.name==="INDUCTION");
          if (fridge || freezer || gaz){
            equipments.description+="Please make sure ";
            if (fridge || freezer){
              equipments.description+=" you have some free space in ";
              if (fridge){
                equipments.description+="the fridge";
              }
              if (fridge && freezer){
                equipments.description+=" and in ";
              }
              if (freezer){
                equipments.description+="the freezer";
              }
            }
            if (gaz){
              if (fridge && freezer){
                equipments.description+=" and";
              }
              equipments.description+=" you won't run out of gaz ";
            }
          }
          if (induction && !this.$root.chefSelected.profile.induction){
            equipments.warning="The chef is not equiped to work with induction, please verify with the chef you have all the equipment he needs";
          }
          console.log(equipments);
          return equipments
        },
        //
        ...mapGetters([
            'findChef',
            'isLoaded'
        ]),
        chefId() {
            return this.$route.params.id;
        },
        chef() {
            console.log(this.$root.chefSelected)
            return this.$root.chefSelected;
        },
        itineraryPath() {
            return `${this.chefId}`;
        },
        errorDishes(){
            if (this.$root.serviceSelected=="classic"){
                if (this.data.starter==null && this.data.desert==null){
                    return true;

                }else {return false;}
            }else{
                return false;
            }
        },
        serviceSelectedCd(){
          if (this.$root.serviceSelected!==null){
            return this.chef.service.find(st => st.serviceType.name===this.$root.serviceSelected.name)
          }else{
            return undefined
          }
        },
        packageSelectedCd(){
          if (this.$root.packageSelected!==null && this.$root.packageSelected!==undefined && this.$root.serviceSelected!==null && this.$root.serviceSelected!==undefined){
            let serviceSelected = this.serviceSelectedCd;
            if (serviceSelected.packages !=null && serviceSelected.packages !==undefined){
              return serviceSelected.packages.find(p => p.name === this.$root.packageSelected.name)
            }else{
              return undefined;
            }
          }else{
            return undefined;
          }
        },
        availableDates(){
          let availableDates={};
          if (this.chef!==undefined){
              if (this.chef.events!=undefined){
                this.chef.events.forEach(e => {
                  if (e.status.name==="AVAILABLE"){
                    availableDates[e.date]=true;
                  }
                })
              }
          }
          return Object.keys(availableDates);
        },
        availableTimes(){
          let times=[];
          if (this.isLunchAvailable){
            times.push.apply(times,
                [
                  '11:00',
                  '11:30',
                  '12:00',
                  '12:30',
                  '13:00',
                  '13:30',
                  '14:00',
                  '14:30',
                  '15:00',
                  '15:30',
                  '16:00',
                  '16:30',
                ]
            );
          }
          if (this.isDinnerAvailable){
            times.push.apply(times,
                [
                  '17:00',
                  '17:30',
                  '18:00',
                  '18:30',
                  '19:00',
                  '19:30',
                  '20:00',
                  '20:30',
                  '21:00',
                  '21:30',
                  '22:00',
                  '22:30',
                  '23:00',
                  '23:30',
                ]
            );
          }
          return times;
        },
        isLunchAvailable(){
          let available=false;
          let dateSelected=this.$root.formData.date;
          if (this.chef!==undefined ){
              if (this.chef.events!=undefined){
                this.chef.events.forEach(e => {
                  if (e.status.name==="AVAILABLE" && e.eventTime.name==="LUNCH"){
                    if (dateSelected!==undefined && e.date===dateSelected){
                      available = true;
                    } else if (dateSelected === undefined || dateSelected == null) {
                      available = true;
                    }
                  }
                })
              }
          }
          return available;
        },
        isDinnerAvailable(){
          let dateSelected=this.$root.formData.date;
          let available=false;

          if (this.chef!==undefined) {
              if (this.chef.events!=undefined){
                this.chef.events.forEach(e => {
                  if (e.status.name === "AVAILABLE" && e.eventTime.name === "DINNER") {
                    if (dateSelected !== undefined && e.date === dateSelected) {
                      available = true;
                    } else if (dateSelected === undefined || dateSelected == null) {
                      available = true;
                    }
                  }
                })
              }
          }
          return available;
            },
          },
        methods: {
          startPaymentProcess(){
            this.$refs.elementRef.submit();
          },
          handlePayment (token, booking) {
            console.log(token);
            let stripe=this.$stripe;
            console.log(stripe);
            this.loading=true;
            this.isLoadingPayment = true;
            let that=this;
            // this.$store.dispatch('asyncGetBookingsAdmin',{pageNumber:0,size:this.updateSize});
            this.$store.dispatch('payment',{reference:booking.reference}).then(function(result){
              console.log(result.result);
              stripe.confirmCardPayment(result.result,{
                payment_method: {
                  card: {
                    token: token.id,
                  },
                },
              }).then(function(result) {
                if (result.error) {
                  // Show error to your customer
                  console.log(result.error.message)
                  that.$store.dispatch('showSnackbar',{text: Constants.PAYMENT_FAILED + ": "+result.error.message, color: "error"});
                  that.isLoadingPayment=false;
                  // show a fix error message to let the user pay again

                  // delete booking or just change the status to payment failed
                  // call check payment instead of waiting for the webhook
                  that.$store.dispatch('checkPayment',{paymentId:result.error.payment_intent.id}).finally(()=>{
                  });
                } else {
                  // The payment succeeded!
                  that.$store.dispatch('showSnackbar', {
                    text: Constants.PAYMENT,
                    color: "primary"
                  })
                  // call check payment instead of waiting for the webhook
                  that.$store.dispatch('checkPayment',{paymentId:result.paymentIntent.id}).finally(()=>{
                    that.isLoadingPayment=false;
                    // redirect to booking details
                    that.$root.bookingToLoad=booking.reference;
                    that.$router.push("/account/bookings");
                  });
                }

              }).finally(()=> {
                //that.isLoadingPayment=false;
              });
            }).catch((error)=> {
              console.log(error);
              that.$store.dispatch('showSnackbar',{text: Constants.PAYMENT_FAILED + ": "+error, color: "error"});
              that.isLoadingPayment=false;
            });
          },
          //Checkout
          checkChip(tags,tag) {
            let found = false;
            if (tags!==undefined){
              found=tags.filter(t => t.name === tag.name).length > 0 ? true : false;
            }else{
              found=false
            }
            // console.log(tag.name +" " + found);
            // return tags !== undefined && tags.filter(t => t.name === tag.name).length > 0 ? true : false;
            return found;
          },
          getServiceCutleryDish(dish){
            for (const equipmentIndex in this.$store.state.equipmentService) {
              let equipment=this.$store.state.equipmentService[equipmentIndex];
              if (dish.equipments!==undefined && dish.equipments.filter(e => e!==undefined && e.name !== equipment.name).length>0) {
                return equipment;
              }
            }
            return undefined
          },
          //
          save(token){
            // this.$refs.elementRef1.submit();
            console.log(this.$refs.form.validate())
            if (Object.keys(this.getServiceCutlery.equipmentsService).length === 0){
              this.equipmentRequired=true;
            }
            if (this.validate()!=true){
              return
            }
            this.isLoadingPayment=true;
            let checkoutRequest={};
            checkoutRequest.nbGuests=this.$root.nbGuests;
            checkoutRequest.nbKids=this.$root.nbKids;
            checkoutRequest.date=this.$root.formData.date;
            checkoutRequest.time=this.$root.formData.time;
            checkoutRequest.comment=this.$root.comment;
            checkoutRequest.haveAllServiceEquipments = this.equipmentRequired;
            checkoutRequest.dishes=[];
            checkoutRequest.serviceTypeId=this.serviceSelectedCd.id;
            checkoutRequest.clientAddress = this.lookupAddress;

            checkoutRequest.hostHouse = this.locationHome;

            if (this.address!=undefined){
              checkoutRequest.address=this.address;
              if ( checkoutRequest.address.addressDetails===null|| checkoutRequest.address.addressDetails===undefined){
                checkoutRequest.address.addressDetails={};
              }
              checkoutRequest.address.addressDetails.equipments=this.equipmentsSelected;
              checkoutRequest.address.addressDetails.cooktopType=this.cooktopType;
            }else{
              let address= {};
              address.fullAddress=this.lookupAddress;
              let addressDetails={}
              addressDetails.equipments=this.equipmentsSelected;
              addressDetails.cooktopType=this.cooktopType;
              address.addressDetails=addressDetails;
              checkoutRequest.address=address;
            }

            Object.keys(this.$root.dishesSelected).forEach(k => {
              if (!k.includes("price") && this.$root.dishesSelected[k]!==undefined ){
                if ( this.$root.dishesSelected[k].length>0){
                  this.$root.dishesSelected[k].forEach(d =>checkoutRequest.dishes.push(d))
                }else{
                  checkoutRequest.dishes.push(this.$root.dishesSelected[k])}
                }
            })

            if (this.locationHome==true){
              checkoutRequest.address.addressDetails.locationType=this.$store.state.locationType.find(l => l.name="RESIDENTIAL_PROPERTY");
            }else{
              checkoutRequest.address.addressDetails.locationType=this.locationType;
            }

            this.$store.dispatch('saveBooking', checkoutRequest)
                .then((response) => {
                  if (response !== undefined && response.statusCode === 200) {
                    this.$store.dispatch('showSnackbar', {
                      text: Constants.SAVED,
                      color: "primary"
                    })
                    console.log(response);
                    this.handlePayment(token, response.result);
                  }
                }).catch(error => {
                  let text="";
                  Object.values(error.validationMessages).forEach(i=>{text+=i+"\n"});
                  if (Object.keys(error.validationMessages).includes("DATE_UNAVAILABLE")){
                    this.$root.formData.date=undefined;
                    this.$root.formData.time=undefined;
                    //
                    console.log(this.$root.chefSelected);
                    let that=this;
                   this.$store.dispatch('getEvents', {id:this.$root.chefSelected.profile.userId}).then(function (result){
                     that.$root.chefSelected.events=result;
                    });
                  }
                  if (error.validationMessages !== undefined) {
                    // text+="Please edit your booking"+"\n";
                    this.error=text;
                  }
                  console.log(error);
                  this.isLoadingPayment=false;
                }) .finally(()=>{
                  // this.isLoadingPayment=false;
                });

          },
          validateEquipmentsKitchen(){
            let equipmentsKitchen=this.getServiceCutlery.equipmentsKitchen;
            let message="Your dishes selection requires: ";
            let missing =false;
            for (const i in equipmentsKitchen){
              let equipment= equipmentsKitchen[i];
              if (this.equipmentsSelected.find(e => e.name===equipment.name)===undefined){
                if (missing){
                  message+=", ";
                }
                message+=equipment.displayName;
                missing=true;
              }
            }
            if (missing){
              return message;
            }
            return undefined;
          },
          validate () {
            if (this.$refs.form!==undefined){
              return this.$refs.form.validate();
            }
          },
          isLunchAvailableDate(date){
            let available=false;
            let dateSelected=date;
            if (this.chef!==undefined ){
              if (this.chef.events!=undefined){
                this.chef.events.forEach(e => {
                  if (e.status.name==="AVAILABLE" && e.eventTime.name==="LUNCH"){
                    if (dateSelected!==undefined && e.date===dateSelected){
                      available = true;
                    } else if (dateSelected === undefined || dateSelected == null) {
                      available = true;
                    }
                  }
                })
              }
            }
            return available;
          },
          isDinnerAvailableDate(date){
            let dateSelected=date;
            let available=false;

            if (this.chef!==undefined) {
              if (this.chef.events!=undefined){
                this.chef.events.forEach(e => {
                  if (e.status.name === "AVAILABLE" && e.eventTime.name === "DINNER") {
                    if (dateSelected !== undefined && e.date === dateSelected) {
                      available = true;
                    } else if (dateSelected === undefined || dateSelected == null) {
                      available = true;
                    }
                  }
                })
              }
            }
            return available;
          },
          functionEvents (date) {
            let classes=[];

            if (date < this.tomorrow.substring(0,10) || !this.isLunchAvailableDate(date) && !this.isDinnerAvailableDate(date)){
              return;
            }
            if (this.isLunchAvailableDate(date)){
              classes.push("available");
            }else{
              classes.push("unavailable");
            }
            if (this.isDinnerAvailableDate(date)){
              classes.push("available");
            }else{
              classes.push("unavailable");
            }
            return classes;
          },
          checkAvailableDate(date){
            if (this.availableDates!==undefined && this.availableDates.length>0){
              return this.availableDates.includes(date);
            }  else{
              return true;
            }
          },
          getLocation(val){
            console.log(val);
            console.log(this.geolocation);
            if (this.$root.formData.location!==undefined){
              this.$root.formData.geolocation=val.center;
              // this.$store.dispatch('getGeolocationFullAddress',this.$root.formData.location).then(response => {
              //     this.geolocation=response;
              //     console.log(this.geolocation);
              // });


            }else{
              this.$root.formData.geolocation=undefined;
            }
            // this.search();

            return this.$root.formData.geolocation;
          },
          checkRange(){
            let result=[false,0];
            if (this.chef.locations!==undefined && this.$root.formData.geolocation){
              for (let i = 0; i < this.chef.locations.length; i++) {
                let location=this.chef.locations[i].location.coordinates;
                let distance = this.getDistanceFromLatLonInKm(location[1],location[0],this.$root.formData.geolocation[1],this.$root.formData.geolocation[0]);
                if (!result[0] && distance<=this.chef.locations[i].maxRange){
                  result=[true,0];
                  break;
                }else{
                  result=[false,(Math.round((distance-this.chef.locations[i].maxRange)*10)/10)];
                }
              }
            }
            return result;
          },
          updatePriceDishesSelected(dishType,dish,checked){
              console.log(dishType,dish,checked);
              console.log(this.dishesSelected)
              if (this.dishesSelected[dishType.name] !==undefined && this.dishesSelected[dishType.name].length !==undefined && this.dishesSelected[dishType.name].length===0){
                  delete this.dishesSelected[dishType.name];
              }
              else if (this.dishesSelected[dishType.name] !==undefined && this.dishesSelected[dishType.name].length !==undefined){
                  let price=0;
                  for (let i = 0; i < this.dishesSelected[dishType.name].length; i++) {
                      price+=this.getPriceWithCommission(this.dishesSelected[dishType.name][i].price);
                  }
                  this.dishesSelected[dishType.name+"price"]=price;
              }
              this.$forceUpdate();

          },
          total(){
            let total=0;
            let totalDishes=0;
            // let waiterPrice=this.waiterPrice();
            // let minChef=this.minChef();
            if (this.$root.packageSelected==null || this.$root.packageSelected===undefined){
              for (const categoryName in this.$root.dishesSelected){
                if (!categoryName.includes("price")){
                  let category = this.serviceSelectedCd.itemCategories.find(c => c.name===categoryName );
                  // Function Cocktail
                  if (this.$root.dishesSelected[categoryName]!==undefined && this.$root.dishesSelected[categoryName].length!==undefined){
                    for (const dishIndex in this.$root.dishesSelected[categoryName]){
                      const dish=this.$root.dishesSelected[categoryName][dishIndex];
                      if (category.categoryType.name=='KID' && this.$root.nbKids!=null){
                        total+=this.getPriceWithCommission(dish.price)*this.$root.nbKids;
                        totalDishes+=this.$root.nbKids*this.$root.dishesSelected[categoryName].length;
                      }else if  (categoryName!=='KID' && this.$root.nbGuests!=null){
                        total+=this.getPriceWithCommission(dish.price)*this.$root.nbGuests;
                        totalDishes+=this.$root.nbGuests*this.$root.dishesSelected[categoryName].length;
                      }
                    }
                  }else if (this.$root.dishesSelected[categoryName]!==undefined){
                    if (category.categoryType.name=='KID'){
                      if (this.$root.nbKids!=null){
                        total+=this.getPriceWithCommission(this.$root.dishesSelected[categoryName].price)*this.$root.nbKids;
                        totalDishes+=this.$root.nbKids;
                      }
                    }else{
                      if (this.$root.nbGuests!=null && this.$root.dishesSelected[categoryName].price !==undefined){
                        total+=this.getPriceWithCommission(this.$root.dishesSelected[categoryName].price)*this.$root.nbGuests;
                        totalDishes+=this.$root.nbGuests;
                      }
                    }
                  }
                }
              }

              if (this.serviceSelectedCd.minWaiterDishes!==undefined && totalDishes>=this.serviceSelectedCd.minWaiterDishes){
                this.waiterPrice= this.roundToXDigits(this.getPriceWithCommission(this.serviceSelectedCd.waiterPrice),2);
                total+=this.waiterPrice;
              }else{
                this.waiterPrice=undefined;
              }

            }else{
              total = this.$root.packageSelected.price * this.$root.nbGuests;
              this.roundToXDigits(total,2);
            }

            if (this.serviceSelectedCd.minPrice!==undefined && total<this.getPriceWithCommission(this.serviceSelectedCd.minPrice)){
              this.minChef = this.roundToXDigits(this.getPriceWithCommission(this.serviceSelectedCd.minPrice)-total,2);
              total+=this.minChef;
            }else{
              this.minChef=undefined;
            }

            return this.roundToXDigits(total,2);

          },
          filteredDishes(service, dishType){
              if (service !== undefined && dishType!==undefined && service.dishes!==undefined){
                  return service.dishes.filter(d => d.dishType!==undefined && d.dishType.name === dishType.name)
              }else{
                  return [];
              }
          },
          filteredDishesKid(service){
              if (service !==undefined && service.dishes!==undefined){
                  return service.dishes.filter(d => d.dishType!==undefined && d.dishType.name === 'KID')
              }
              return;
          },
          changeservice(serviceType){
              this.$root.serviceSelected=serviceType;
              //reset data
              this.$root.dishesSelected=[];
              this.data=initData().data;
              this.setMain();
          },
          toggleChef() {
              this.data.aboutChef = !this.data.aboutChef;
          },
          setMain() {
              if (this.chef && this.$root.serviceSelected=="classic") {
                  this.data.main = this.chef.main[0];
                  console.log('setMain');
              }
          },
          test(data) {
              console.log(this.$data);
              console.log(data);
          },
        },
        watch: {
            isLoaded: function (val) {
                if (val) {
                    this.data.main = this.chef.main[0];
                } else {
                    console.log("not loaded yet");
                }
                console.log("chef:", val);
            },
          lookupAddress (val) {
            if (val===undefined || val===null || val.length<4) {
              return
            }
            // Items have already been loaded
            // if (this.items !==undefined && this.items.length > 0) return

            // Items have already been requested
            if (this.isLoading) return

            this.isLoading = true

            // Lazily load input items
            let that=this;

            // using mapbox
            this.$store.dispatch('lookupAddressMapbox',val).
            then(response => {
              that.$root.formData.locationItems=response; })
                .finally(() => (this.isLoading = false));
          },
        }
        // }
    };
</script>
